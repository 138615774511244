import { Printd } from 'printd';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Modal from 'bootstrap/js/dist/modal';

import './javascript_landingpage';

/* Helpers */

function fragmentFromString(strHTML) {
  return document.createRange().createContextualFragment(strHTML);
}

function delegate({ event: eventName = 'click', target = document, selector, fn } = {}) {
  target.addEventListener(
    eventName,
    (e) => {
      const trigger = e.target.closest(selector);

      if (trigger) {
        switch (eventName) {
          case 'mouseover':
            if (trigger.dataset.mouseovered !== 'true') {
              trigger.dataset.mouseovered = 'true';
              fn(e, trigger);
            }
            break;
          case 'mouseout':
            if (trigger.dataset.mouseovered === 'true' && !trigger.contains(e.relatedTarget)) {
              fn(e, trigger);
              delete trigger.dataset.mouseovered;
            }
            break;
          default:
            fn(e, trigger);
        }
      }
    },
    false
  );
}

// Change color of list bullets and numbers
const PrependSPANinLI = function () {
  [].slice.call(document.querySelectorAll('ul > li,ol > li')).forEach((elem) => {
    const existingHTML = elem.innerHTML;

    elem.innerHTML = `<span>${existingHTML}</span>`;
  });
};

// Add hasbtn class to anchor tags containing button DIVs
const ahasbtn = function () {
  [].slice.call(document.querySelectorAll('a div.btn')).forEach((elem) => {
    elem.closest('a').classList.add('hasbtn');
  });
};

// Replace all SVG images with inline SVG
const SVGtoInline = function () {
  [].slice.call(document.querySelectorAll('img.svg')).forEach((img) => {
    const imgID = img.id;
    const imgClass = img.className;
    const imgURL = img.src;

    fetch(imgURL)
      .then((response) => response.text())
      .then((data) => {
        // Get the SVG tag, ignore the rest
        const svg = fragmentFromString(data).querySelector('svg');
        // Add replaced image's ID to the new SVG
        if (imgID) {
          svg.id = imgID;
        }
        // Add replaced image's classes to the new SVG
        if (imgClass) {
          svg.setAttribute('class', `${imgClass} replaced-svg`);
        }
        svg.removeAttribute('xmlns');
        // Replace image with new SVG
        img.parentNode.replaceChild(svg, img);
      });
  });
};

// // Dynamic Video Width
// const VideoDynamic = function () {
//   const allVideos = [].slice.call(document.querySelectorAll('iframe')).filter(function (elem) {
//     return elem.src.match(
//       /\s*(https?:\/\/www.youtube(?:-nocookie).com\/(?:v|embed)\/([a-zA-Z0-9-]+)|https?:\/\/([a-z0-9]+[.])*wistia.net.*)/
//     );
//   });
//
//   // The element that is fluid width
//   //let fluidEl = document.querySelector(".video-container");
//
//   // Figure out and save aspect ratio for each video
//   allVideos.forEach(function (elem) {
//     //let fluidEl = document.querySelector(".video-container");
//     let fluidEl = document.querySelector('.container');
//     elem.dataset['aspectRatio'] = elem.height / elem.width;
//     // and remove the hard coded width/height
//     elem.removeAttribute('height')
//     elem.removeAttribute('width');
//   });
//   // When the window is resized
//   // (You'll probably want to debounce this)
//   function onWindowResize() {
//     //$(function() {
//
//     let newWidth = fluidEl.getBoundingClientRect().width;
//     // Resize all videos according to their own aspect ratio
//     allVideos.forEach(function (elem) {
//       //let newWidth = fluidEl.getBoundingClientRect().width;
//
//       elem.style.width = `${newWidth}px`;
//       elem.style.height = `${newWidth * elem.dataset['aspectRatio']}px`;
//
//
//     });
//   }
//   window.addEventListener('resize', onWindowResize, false);
//   onWindowResize();
// };

// Find all video iframes
const videoiframes = [].slice.call(document.querySelectorAll('iframe')).filter((elem) =>
  // Regex includes YouTube and Wistia
  elem.src.match(
    /\s*(https?:\/\/www.youtube(?:-nocookie).com\/(?:v|embed)\/([a-zA-Z0-9-]+)|https?:\/\/([a-z0-9]+[.])*wistia.net.*)/
  )
);

// Find and save the aspect ratio for all video iframes
videoiframes.forEach((iframe) => {
  iframe.dataset.ratio =
    iframe.height /
    (iframe.width.indexOf('%') === -1
      ? iframe.width
      : iframe.parentNode.getBoundingClientRect().width);
  // Remove the hardcoded width and height attributes
  iframe.removeAttribute('width');
  iframe.removeAttribute('height');
});

function setvideoiframe() {
  videoiframes.forEach((iframe) => {
    // Get the parent container width
    const { width } = iframe.parentNode.getBoundingClientRect();

    iframe.style.width = `${width}px`;
    iframe.style.height = `${width * iframe.dataset.ratio}px`;
  });
}
// Resize the iframes when the window is resized
window.addEventListener('resize', setvideoiframe, false);
setvideoiframe();

// FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
function autoPlayYouTubeModal() {
  delegate({
    event: 'click',
    selector: '[data-toggle="modal"]',
    fn(e, trigger) {
      const theModalSelector = trigger.dataset.bsTarget;
      const videoSRC = trigger.dataset.thevideo;
      const videoSRCauto = `${videoSRC}?autoplay=1&autoPlay=true`;

      document.querySelector(`${theModalSelector} iframe`).setAttribute('src', videoSRCauto);

      function onClose() {
        document.querySelector(`${theModalSelector} iframe`).setAttribute('src', videoSRC);
        document
          .querySelector(theModalSelector)
          .removeEventListener('hidden.bs.modal', onClose, false);
      }

      document.querySelector(theModalSelector).addEventListener('hidden.bs.modal', onClose, false);
    },
  });
}
function initTextareaAutoSize(selector) {
  function OnInput() {
    this.style.height = 'auto';
    this.style.height = `${this.scrollHeight}px`;
  }

  [].slice.call(document.querySelectorAll(selector)).forEach((tx) => {
    tx.setAttribute('style', `height:${tx.scrollHeight}px;overflow-y:hidden;`);
    tx.addEventListener('input', OnInput, false);
  });
}

function initCollapseCard() {
  const slideDown = (elem) => {
    elem.style.height = `${elem.scrollHeight}px`;
  };
  const slideUp = (elem) => {
    elem.style.height = 0;
  };
  const activeClass = 'active';

  function onSlideUp(e) {
    e.target.closest(`.${activeClass}`).classList.remove(activeClass);
    e.target.removeEventListener('transitionend', onSlideUp, false);
  }

  delegate({
    event: 'click',
    selector: '.collapse-card__heading, .collapse-card__close_handler',
    fn(e, trigger) {
      const card = trigger.closest('.collapse-card');
      const cardBody = card.querySelector('.collapse-card__body');

      if (card.classList.contains(activeClass)) {
        cardBody.addEventListener('transitionend', onSlideUp, false);
        slideUp(cardBody);
      } else {
        card.classList.add(activeClass);
        slideDown(cardBody);
        setvideoiframe();
      }
    },
  });
}

function initTooltips() {
  const tooltipTriggerSelector = '[data-bs-toggle="tooltip"]';
  const tooltipClass = 'js-tooltip';

  const options = {
    html: true,
    trigger: 'manual',
    customClass: tooltipClass,
    sanitize: false,
  };
  let showTid;
  let hideTid;
  const showDelay = 200;
  const hideDelay = 100;

  delegate({
    event: 'mouseover',
    selector: tooltipTriggerSelector,
    fn(e, trigger) {
      const tooltipApi = Tooltip.getInstance(trigger);

      clearTimeout(hideTid);
      showTid = setTimeout(() => {
        if (!tooltipApi.tip || tooltipApi.tip.getClientRects().length === 0) {
          tooltipApi.show();
        }
      }, showDelay);
    },
  });

  delegate({
    event: 'mouseout',
    selector: tooltipTriggerSelector,
    fn(e, trigger) {
      const tooltipApi = Tooltip.getInstance(trigger);

      clearTimeout(showTid);
      hideTid = setTimeout(() => {
        if (
          tooltipApi.tip &&
          e.relatedTarget !== tooltipApi.tip &&
          !tooltipApi.tip.contains(e.relatedTarget)
        ) {
          tooltipApi.hide();
        }
      }, hideDelay);
    },
  });

  delegate({
    event: 'mouseover',
    selector: `.${tooltipClass}`,
    fn() {
      clearTimeout(hideTid);
    },
  });

  delegate({
    event: 'mouseout',
    selector: `.${tooltipClass}`,
    fn(e, trigger) {
      const tooltipApi = Tooltip.getInstance(trigger);

      hideTid = setTimeout(() => {
        if (
          /* eslint-disable no-underscore-dangle */
          tooltipApi._element &&
          e.relatedTarget !== tooltipApi._element &&
          !tooltipApi._element.contains(e.relatedTarget)
          /* eslint-enable no-underscore-dangle */
        ) {
          tooltipApi.hide();
        }
      }, hideDelay);
    },
  });

  [].slice
    .call(document.querySelectorAll(tooltipTriggerSelector))
    .forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, options));
}

const AlertClose = function () {
  const alertSelector = '.alert';

  function onHidden(e) {
    e.target.removeEventListener('transitionend', onHidden, false);
    e.target.parentNode.removeChild(e.target);
  }

  delegate({
    event: 'click',
    selector: `${alertSelector} button.close`,
    fn(e, trigger) {
      trigger.closest(alertSelector).addEventListener('transitionend', onHidden, false);
      trigger.closest(alertSelector).style.opacity = 0;
    },
  });
};

// Toggler
const Toggler = function () {
  function hideAllBut(selector) {
    const theOne = document.querySelector(selector);

    [].slice.call(document.querySelectorAll('.toggler')).forEach((toggler) => {
      toggler.hidden = true;
    });

    if (theOne) {
      theOne.hidden = false;
    }
  }

  hideAllBut('.togglerItem1');

  delegate({
    event: 'click',
    selector: '[data-toggle].btn-toggler',
    fn(e, trigger) {
      e.preventDefault(); // prevent navigating
      [].slice.call(trigger.parentNode.querySelectorAll('.btn')).forEach((elem) => {
        elem.classList.remove('active', 'btn-primary');
        elem.classList.add('btn-default');
      });
      trigger.classList.add('active', 'btn-primary');
      hideAllBut(trigger.dataset.bsToggle);
    },
  });
};

// var SetSecondaryNavigation = function () {
//   var parts = location.hostname.split('.');
//   var subdomain = parts.shift();
//   var upperleveldomain = parts.join('.');
//   var sndleveldomain = parts.slice(-2).join('.');
// };

// run code when DOM is ready
function onDocumentReady() {
  PrependSPANinLI();
  SVGtoInline();
  ahasbtn();
  AlertClose();
  Toggler();
  autoPlayYouTubeModal();
  initTooltips();
  // VideoDynamic();
  initTextareaAutoSize('textarea.js-auto-size');
  initCollapseCard();

  delegate({
    event: 'mouseover',
    selector: '.hoverSwap',
    fn(e, trigger) {
      trigger.dataset.srcOriginal = trigger.getAttribute('src');
      trigger.setAttribute('src', trigger.dataset.srcHover);
      trigger.setAttribute('srcset', trigger.dataset.srcHover);
    },
  });
  delegate({
    event: 'mouseout',
    selector: '.hoverSwap',
    fn(e, trigger) {
      trigger.setAttribute('src', trigger.dataset.srcOriginal);
      trigger.setAttribute('srcset', trigger.dataset.srcOriginal);
    },
  });

  // Preload hoverSwap images
  [].slice.call(document.querySelectorAll('.hoverSwap')).forEach((elem) => {
    new Image().src = elem.dataset.srcHover;
  });

  function activateSearchField() {
    document.querySelector('#search_query').focus();
  }
  const navSearchTrigger = document.querySelector('#ToolsNavigationItemSearch');

  if (navSearchTrigger) {
    navSearchTrigger.addEventListener('click', activateSearchField, false);
    navSearchTrigger.addEventListener('mouseenter', activateSearchField, false);
  }

  [].slice.call(document.querySelectorAll('.print')).forEach((elem) => {
    elem.addEventListener(
      'click',
      () => {
        const d = new Printd();
        d.print(elem.closest('.printArea'));
      },
      false
    );
  });

  delegate({
    event: 'click',
    selector: '.toggleDisplay',
    fn(e, trigger) {
      if (trigger.matches('.showOnly')) {
        trigger.hidden = true;
      }
      if (trigger.matches('p, div')) {
        const txt = trigger.textContent;

        trigger.textContent = txt === 'Show More' ? 'Show Less' : 'Show More';
      }

      if (trigger.previousElementSibling.matches('.toggleContent')) {
        trigger.previousElementSibling.style.display = '';
      }
    },
  });

  // Inline Audio Player
  let audio;
  delegate({
    event: 'click',
    selector: '.playAudio',
    fn(e, trigger) {
      if (audio) {
        audio.pause();
      }
      audio = document.createElement('audio');
      audio.setAttribute('src', trigger.dataset.audio);
      audio.currentTime = 0;
      audio.play();
    },
  });

  // Escape key doesn't work in IE11 and Edge 12-18.
  // Include global (tiny) polyfill
  // https://caniuse.com/keyboardevent-key
  // https://github.com/shvaikalesh/shim-keyboard-event-key

  /* eslint-disable */
  (function () {
    if (!self.document) {
      return;
    }

    const event = KeyboardEvent.prototype;
    const desc = Object.getOwnPropertyDescriptor(event, 'key');
    if (!desc) {
      return;
    }

    const keys = {
      Win: 'Meta',
      Scroll: 'ScrollLock',
      Spacebar: ' ',

      Down: 'ArrowDown',
      Left: 'ArrowLeft',
      Right: 'ArrowRight',
      Up: 'ArrowUp',

      Del: 'Delete',
      Apps: 'ContextMenu',
      Esc: 'Escape',

      Multiply: '*',
      Add: '+',
      Subtract: '-',
      Decimal: '.',
      Divide: '/',
    };

    Object.defineProperty(event, 'key', {
      get() {
        const key = desc.get.call(this);

        return keys.hasOwnProperty(key) ? keys[key] : key;
      },
    });
  })();
  /* eslint-enable */

  // better modal tabbing
  function patchModalFocus() {
    // https://github.com/twbs/bootstrap/issues/28481#issuecomment-763017750
    const TAB_KEY = 9;
    const focusableSelectors = [
      'a[href]:not([tabindex^="-"])',
      'area[href]:not([tabindex^="-"])',
      'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
      'input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked',
      'select:not([disabled]):not([tabindex^="-"])',
      'textarea:not([disabled]):not([tabindex^="-"])',
      'button:not([disabled]):not([tabindex^="-"])',
      'iframe:not([tabindex^="-"])',
      'audio[controls]:not([tabindex^="-"])',
      'video[controls]:not([tabindex^="-"])',
      '[contenteditable]:not([tabindex^="-"])',
      '[tabindex]:not([tabindex^="-"])',
    ];

    function getFocusableChildren(node) {
      return [].filter.call(
        node.querySelectorAll(focusableSelectors.join(',')),
        (child) => !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length)
      );
    }

    function trapTabKey(node, event) {
      const focusableChildren = getFocusableChildren(node);
      const focusedItemIndex = focusableChildren.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableChildren[focusableChildren.length - 1].focus();
        event.preventDefault();
      } else if (!event.shiftKey && focusedItemIndex === focusableChildren.length - 1) {
        focusableChildren[0].focus();
        event.preventDefault();
      }
    }

    function bindKeypress(event, modal) {
      if (modal && event.which === TAB_KEY) {
        trapTabKey(modal, event);
      }
    }

    const handler = {
      modal: null,
      handleEvent(e) {
        bindKeypress(e, this.modal);
      },
    };

    document.addEventListener('show.bs.modal', (evt) => {
      handler.modal = evt.target;
      document.addEventListener('keydown', handler);
    });
    document.addEventListener('hide.bs.modal', () => {
      handler.modal = null;
      document.removeEventListener('keydown', handler);
    });
  }

  patchModalFocus();

  // escape closing isn't working for a hard to find reason, brute force it
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      Modal.getInstance(document.querySelector('.modal.show')).hide();
    }
  });

  // make bootstrap v3 HTML work with bootstrap v5 JS
  function adaptBootstrapModals() {
    const attrNames = ['toggle', 'target', 'dismiss'];

    function attributify(attrName, elem) {
      const bootstrapPrefix = 'bs';
      const attrVal = elem.getAttribute(`data-${attrName}`);

      if (attrVal) {
        elem.setAttribute(`data-${bootstrapPrefix}-${attrName}`, attrVal);
      }
    }

    [].slice
      .call(
        document.querySelectorAll(
          attrNames.reduce(
            (accumulator, val, i) => `${accumulator + (i ? ', ' : '')}[data-${val}]`,
            ''
          )
        )
      )
      .forEach((elem) => {
        attrNames.forEach((attr) => {
          attributify(attr, elem);
        });
      });
  }

  adaptBootstrapModals();
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', onDocumentReady);
} else {
  onDocumentReady();
}

// live reload for dev mode
if (process.env.NODE_ENV !== 'production') {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = 'http://localhost:35729/livereload.js';
  document.body.appendChild(s);
}
